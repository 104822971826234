import React, { useContext, useEffect, useState } from "react";
import Footer from "../../global/Footer";
import Navbar from "../../global/NavbarSuper";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../contexts/Status";
import ClientCompanySelector from "../../components/common/ClientCompanySelector"; // Import the selector component

const DashboardSuper = () => {
  const { setSelectedIDs } = useContext(StatusContext);
  const navigate = useNavigate();
  const [showSelector, setShowSelector] = useState(false);
  const [currentLink, setCurrentLink] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientName, setClientName] = useState(null);
  const [companyID, setCompanyID] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const approvalGroup_ID = "none";
  const [Email, setEmail] = useState(null);
  const { updateClientAndCompany } = useContext(StatusContext);

  const topLinks = [
    { name: "Admin", link: "/admin/dashboard"},
    { name: "Manager", link: "/admin/dashback" },
    { name: "Approver", link: "/approver/dashboard" },
  ];

  const botLinks = [
    { name: "Auditor", link: "/auditor" },
    { name: "HR", link: "/hr/dashboard" },
    { name: "Executive", link: "/executive" },
    { name: "Recorder", link: "/recorder" },
  ];

  useEffect(() => {
    document.title = "Super Dashboard";
  }, []);

  const handleLinkClick = (link) => {
    setCurrentLink(link);
    setShowSelector(true);
  };

  const handleSelection = (clientID, companyID) => {
    updateClientAndCompany(clientID, companyID);
    setShowSelector(false);
    navigate(currentLink);
  };

  const navigateToSelectedLink = (clientID, companyID) => {
    // Assuming clientName and companyName are updated based on the selection
    // and Email, approvalGroup_ID are fetched or set in the component's state
    const encodedEmail = window.btoa(Email);
    const encodedClientID = window.btoa(clientID);
    const encodedClientName = window.btoa(clientName);
    const encodedCompanyID = window.btoa(companyID);
    const encodedCompanyName = window.btoa(companyName);
    const encodedApprovalGroupID = window.btoa(approvalGroup_ID);

    // Update the AccountContext or StatusContext with new entitlements
    // This can be a function that updates the context based on new selections
   /* updateContextsWithSelections({
        email: Email,
        clientID,
        clientName,
        companyID,
        companyName,
        approvalGroup_ID,
    });
*/
    // Navigate to the selected link with updated parameters
    navigate(`/${encodedEmail}/${encodedClientID}/${encodedClientName}/${encodedCompanyID}/${encodedCompanyName}/${encodedApprovalGroupID}${currentLink}`);
};


  return (
    <div className="bg-[#F2F2F2] flex flex-col min-h-screen items-center font-fabriga mx-auto">
      <Navbar name="DASHBOARD" />
      {/* ... */}
      {topLinks.map((link) => (
        <button
          onClick={() => handleLinkClick(link.link)}
          className="my-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
        >
          {link.name}
        </button>
      ))}
      {/* ... */}
      {botLinks.map((link) => (
        <button
          onClick={() => handleLinkClick(link.link)}
          className="my-10 bg-purple-500 text-white active:bg-purple-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
        >
          {link.name}
        </button>
      ))}
      {/* ClientCompanySelector Component */}
      {showSelector && (
        <ClientCompanySelector
          onSelection={handleSelection}
          onClose={() => setShowSelector(false)}
        />
      )}
      <Footer />
    </div>
  );
};

export default DashboardSuper;
