import {
  BanknotesIcon,
  BeakerIcon,
  ChartBarIcon,
  ClockIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFemale,
  faMale,
  faCircleUp,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import {Bar} from "react-chartjs-2";
import Navbar from "../../global/NavbarAdmin";
import AdminTableComponent from "./Components/AdminTableComponent";
import LineChart from "./Components/LineChart";
import React, {useContext, useEffect, useState} from "react";
import {SelectColumnFilter} from "./Components/AdminTable";
import Status, {StatusContext} from "../../contexts/Status"
import {useNavigate} from "react-router-dom";
import TopNavigation from "../../components/common/TopNavigation";
import {AdminContext} from "./Admin";
import BarChart from "./Components/BarChart";
import {Line} from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import Footer from "../../global/Footer";
import Spinner from "../../global/Spinner";
import {getDepartment, getDeptDetails, getEmployees} from "../../global/api";
import DepartmentTableComponent from "./Components/Department/DepartmentTableComponent";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Avatar,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Chip,
  Collapse,
  IconButton,
  Input,
  Tab,
  Tabs,
  TabsHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import Sidebar from "./Sidebar";
import Breadcrumb from "./Components/Breadcrumbs";
import StatisticsChart from "./Components/Dashboard/DataChart";
import chartsConfig from "./Components/Org/charts-config";
import {useParams} from "react-router-dom";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const AdminDashoardDepartment = () => {
  // const {CompanyID} = useContext(StatusContext);

  const {
    Email, 
    ClientID, 
    ClientName, 
    CompanyID, 
    CompanyName,
  } = useContext(StatusContext);

  const [costOfRisk, setCostOfRisk] = useState(0);
  const [costOfRiskText, setCostOfRiskText] = useState(
    "Total Cost Of Flight Risk"
  );
  const [empData, setEmpData] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [departmentDetails, setDepartmentDetails] = useState(null);
  const {setSelectedDept, selectedDept} = useContext(AdminContext);
  const [awaitEmployees, setAwaitEmployees] = useState(true);
  const [departmentBars, setDepartmentBars] = useState(null);

  const getDepartments = async () => {
    const departmentdetail = await getDeptDetails(CompanyID);
    console.log("department details", departmentdetail);
    setDepartmentDetails(departmentdetail);
    const departments = await getDepartment(CompanyID);
    console.log("departments", departments);
    setDepartments(departments);

    departments.forEach((department) => {
      const total = department.count_total;
      const redPercentage = (department.count_red / total) * 100;
      const yellowPercentage = (department.count_yellow / total) * 100;
      const greenPercentage = 100 - redPercentage - yellowPercentage;
      department.redPercentage = redPercentage;
      department.yellowPercentage = yellowPercentage;
      department.greenPercentage = greenPercentage;
    });

    departments.sort((a, b) => {
      if (a.redPercentage !== b.redPercentage) {
        return b.redPercentage - a.redPercentage;
      } else if (a.yellowPercentage !== b.yellowPercentage) {
        return b.yellowPercentage - a.yellowPercentage;
      } else {
        return b.greenPercentage - a.greenPercentage;
      }
    });
    setDepartmentBars(departments);

    let cost = 0;
    departments.map(
      (department) =>
        (cost += parseFloat(department.total_risk.replace(/[$,]/g, "")))
    );
    setCostOfRisk(cost);
  };

  useEffect(() => {
    if (CompanyID) {
      getDepartments();
    }
  }, [CompanyID]);

  const fetchEmployees = async () => {
    setAwaitEmployees(true);
    const fetchedEmployees = await getEmployees(
      CompanyID,
      selectedDept.Department_ID
    );
    console.log(fetchedEmployees);
    setEmpData(fetchedEmployees);
    setAwaitEmployees(false);
  };

  useEffect(() => {
    if (selectedDept) {
      setCostOfRisk(selectedDept.total_risk.replace(/[$,]/g, ""));
      setCostOfRiskText(
        "Cost of Flight Risk - " + selectedDept.Department_Name
      );
      fetchEmployees();
    }
  }, [selectedDept]);

  const ITEMS_PER_PAGE = 5;

  const [currentPage, setCurrentPage] = useState(1);

  // Function to get the data for the current page
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Filter the data based on the search query
    // const filteredData = jobs.filter((employee) =>
    //   `${employee.First_Name} ${employee.Last_Name}`
    //     .toLowerCase()
    //     .includes(searchQuery.toLowerCase())
    // );

    return departmentBars.slice(startIndex, endIndex);
  };

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle pagination
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const websiteViewsChart = departments
    ? {
        type: "bar",
        height: 220,
        series: [
          {
            name: "High Risk",
            data: getCurrentPageData().map((department) => {
              return (department.count_red / department.count_total) * 100;
            }),
          },
          {
            name: "Med Risk",
            data: getCurrentPageData().map(
              (department) =>
                (department.count_yellow / department.count_total) * 100
            ),
          },
          {
            name: "Low Risk",
            data: getCurrentPageData().map(
              (department) =>
                ((department.count_total -
                  department.count_red -
                  department.count_yellow) /
                  department.count_total) *
                100
            ),
          },
        ],
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          stackType: "100%",
        },
        options: {
          ...chartsConfig,
          colors: ["#B81D13", "#EFB700", "#008450"],
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            stackType: "100%",
          },
          plotOptions: {
            bar: {
              horizontal: true, // Set horizontal to true for a horizontal stacked bar chart
              barHeight: "80%", // Adjust barHeight for better spacing between bars
              borderRadius: 5,
            },
          },

          xaxis: {
            ...chartsConfig.yaxis, // Use the yaxis config for the horizontal X-axis
            categories: getCurrentPageData().map(
              (department) => department.Department_Name
            ),
          },
        },
      }
    : null;

  const departmentChartsData = [
    {
      color: "white",
      title: "Stackup by Department",
      description: "Department levels",
      footer: "updated today",
      chart: websiteViewsChart,
    },
  ];

  const statisticsCardsData = [
    {
      color: "blue",
      icon: BanknotesIcon,
      title: "Total Risk",
      value: costOfRisk ? costOfRisk : "0",
      footer: {
        color: "text-red-500",
        value: "+20%",
        label: "than last week",
      },
    },
  ];

  const notWebsiteViewsChart = {
    type: "bar",
    height: 220,
    series: [
      {
        name: "Views",
        data: [50, 20, 10, 22, 50, 10, 40],
      },
    ],
    options: {
      ...chartsConfig,
      colors: "#fff",
      plotOptions: {
        bar: {
          columnWidth: "16%",
          borderRadius: 5,
        },
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: ["Foo", "Bar", "Merc", "Lewis", "Lando", "Charles", "Ford"],
      },
    },
  };
  const dailySalesChart = {
    type: "line",
    height: 220,
    series: [
      {
        name: "Serious Flight Risk",
        data: [10000, 200000, 300000, 400000],
      },
    ],
    options: {
      ...chartsConfig,
      colors: ["#fff"],
      stroke: {
        lineCap: "round",
      },
      markers: {
        size: 5,
      },
      xaxis: {
        ...chartsConfig.xaxis,
        categories: [10, 10, 10, 10, 10, 20, 20, 30, 40],
      },
    },
  };
  const completedTasksChart = {
    ...dailySalesChart,
    series: [
      {
        name: "Tasks",
        data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
      },
    ],
  };

  const statisticsChartsData = [
    {
      color: "green",
      title: "Risk Over Time",
      description: "Risk Over Time",
      footer: "just updated",
      chart: completedTasksChart,
    },
  ];

  return (
    <>
      {/* <Navbar name="DASHBOARD"></Navbar> */}
      {departments === null ? (
        <Spinner />
      ) : (
        <div className="flex">
          <Sidebar />
          <div className="flex flex-col bg-[#F2F2F2] justify-center min-h-screen items-center font-fabriga w-screen">
            <Breadcrumb
              link={"/admin/Dashboard"}
              linkCrumb={"/Dashboard/Department"}
              title={"Department"}
            ></Breadcrumb>
                      <TopNavigation />
            <div className="h-7"></div>
            <div className="w-4/5">
              <div className="mt-20 mb-6">
                {departmentChartsData.map((props) => (
                  <StatisticsChart
                    pagination={
                      <div className="flex gap-2 p-2">
                        <Button
                          variant="outlined"
                          color="blue-gray"
                          size="sm"
                          onClick={() => handlePageChange(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="outlined"
                          color="blue-gray"
                          size="sm"
                          onClick={() => handlePageChange(currentPage + 1)}
                          disabled={
                            currentPage ===
                            Math.ceil(departmentBars.length / ITEMS_PER_PAGE)
                          }
                        >
                          Next
                        </Button>
                      </div>
                    }
                    key={props.title}
                    {...props}
                    footer={
                      <Typography
                        variant="small"
                        className="flex items-center font-normal text-blue-gray-600"
                      >
                        <ClockIcon
                          strokeWidth={2}
                          className="h-4 w-4 text-inherit"
                        />
                        &nbsp;{props.footer}
                      </Typography>
                    }
                  />
                ))}
              </div>
              <div className="h-7"></div>
              <div className="flex flex-col w-full">
                {statisticsCardsData.map(({icon, title, footer, ...rest}) => (
                  <StatisticsCard
                    key={title}
                    {...rest}
                    title={title}
                    icon={React.createElement(icon, {
                      className: "w-6 h-6 text-white",
                    })}
                    footer={
                      <Typography className="font-normal text-blue-gray-600">
                        <strong className={footer.color}>{footer.value}</strong>
                        &nbsp;{footer.label}
                      </Typography>
                    }
                  />
                ))}
                <div className="mt-10">
                  {statisticsChartsData.map((props) => (
                    <StatisticsChart
                      key={props.title}
                      {...props}
                      footer={
                        <Typography
                          variant="small"
                          className="flex items-center font-normal text-blue-gray-600"
                        >
                          <ClockIcon
                            strokeWidth={2}
                            className="h-4 w-4 text-inherit"
                          />
                          &nbsp;{props.footer}
                        </Typography>
                      }
                    />
                  ))}
                </div>
              </div>
            </div>

            <div className="h-7"></div>
            <Footer></Footer>
          </div>
        </div>
      )}
    </>
  );
};

export default AdminDashoardDepartment;

function StatisticsCard({color, icon, title, value, footer}) {
  return (
    <Card>
      <CardHeader
        variant="gradient"
        color={color}
        className="absolute -mt-4 grid h-16 w-16 place-items-center"
      >
        {icon}
      </CardHeader>
      <CardBody className="p-4 text-right">
        <Typography variant="small" className="font-normal text-blue-gray-600">
          {title}
        </Typography>
        <Typography variant="h4" color="blue-gray">
          {value}
        </Typography>
      </CardBody>
      {footer && (
        <CardFooter className="border-t border-blue-gray-50 p-4">
          {footer}
        </CardFooter>
      )}
    </Card>
  );
}
