// CompanyDataContext.js

import React, { createContext, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import { getCompanies } from '../global/api'; 

const CompanyDataContext = createContext();

export const CompanyDataProvider = ({ children, companyId }) => {

  const [companyData, setCompanyData] = useState(null);

  const fetchData = useCallback(async () => {
    const data = await getCompanies(companyId);
    setCompanyData(data);
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Memoize context value
  const contextValue = useMemo(() => ({
    companyData, 
    loading: !companyData 
  }), [companyData]);

  return (
    <CompanyDataContext.Provider value={contextValue}>
      {children}
    </CompanyDataContext.Provider>
  );

};
export const useCompanyData = () => useContext(CompanyDataContext);
export const useCompanyDataLoading = () => {
  const { loading } = useCompanyData();
  return loading;
}