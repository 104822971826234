import React, { useContext, useEffect, useState } from 'react';
import { StatusContext } from '../../contexts/Status';
import { getApprovalGroupsEmail, actionPending } from '../../global/api';
import { useTable } from 'react-table';

const PendingApprovalsGrid = () => {
    const { CompanyID, Email } = useContext(StatusContext);
    const [pendingApprovals, setPendingApprovals] = useState([]);

    useEffect(() => {
        const fetchApprovalGroupsAndActions = async () => {
            if (!CompanyID || !Email) return;

            try {
                // Step 1: Fetch approval groups by email
                const approvalGroups = await getApprovalGroupsEmail(CompanyID);
                console.log("Approval Groups:", approvalGroups);
                // Step 2: Fetch pending actions for each approval group
                const actionsPromises = approvalGroups.map(group => 
                    actionPending(CompanyID, group.Approval_Group_ID)
                );
                console.log("Actions Promises:", actionsPromises);            
                const actionsResults = await Promise.all(actionsPromises);
                
                // Flatten the results and set them to state
                const flattenedResults = actionsResults.flat();
                console.log("Flattened Results:", flattenedResults);
                setPendingApprovals(flattenedResults);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchApprovalGroupsAndActions();
    }, [CompanyID, Email]);

    const columns = React.useMemo(
        () => [
            // Define your columns based on the structure of the data
            { Header: 'Action Name', accessor: 'actionName' },
            { Header: 'Requested By', accessor: 'requestedBy' },
            // Add more columns as necessary
        ],
        []
    );

    const tableInstance = useTable({ columns, data: pendingApprovals });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div>
            <h2 className="text-center text-lg font-semibold">Pending Approvals</h2>
            <table {...getTableProps()} className="w-full">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default PendingApprovalsGrid;
