import React, { useState, useEffect, useContext } from "react";
import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Select, Option } from "@material-tailwind/react";
import { getClientComps, getClients } from "../../global/api";
import { StatusContext } from "../../contexts/Status";

const ClientCompanySelector = ({ onSelection, onClose }) => {
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClientID, setSelectedClientID] = useState("");
  const [selectedCompanyID, setSelectedCompanyID] = useState("");
  const { updateClientAndCompany } = useContext(StatusContext);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const fetchedClients = await getClients();
        setClients(fetchedClients);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const fetchedCompanies = await getClientComps(selectedClientID);
        setCompanies(fetchedCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    if (selectedClientID) {
      fetchCompanies();
    }
  }, [selectedClientID]);

  const handleConfirm = () => {
    updateClientAndCompany(selectedClientID, selectedCompanyID);
    onSelection(selectedClientID, selectedCompanyID);
    onClose();
  };

  return (
    <Dialog open={true} handler={onClose}>
      <DialogHeader>Select a Client and Company</DialogHeader>
      <DialogBody>
        <Select label="Select Client" onChange={(value) => setSelectedClientID(value)}>
          {clients.map((client) => (
            <Option key={client.Client_ID} value={client.Client_ID}>
              {client.Client_Name}
            </Option>
          ))}
        </Select>
        {selectedClientID && (
          <Select label="Select Company" onChange={(value) => setSelectedCompanyID(value)}>
            {companies.map((company) => (
              <Option key={company.Company_ID} value={company.Company_ID}>
                {company.Company_Name}
              </Option>
            ))}
          </Select>
        )}
      </DialogBody>
      <DialogFooter>
        <Button variant="text" color="red" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="gradient" color="green" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default ClientCompanySelector;
