import { EmployeeContext } from '../../contexts/EmployeeContext';
import React, { useMemo, useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { StatusContext } from '../../contexts/Status';
import { useTable, usePagination, useGlobalFilter } from 'react-table';
import { Tooltip } from 'react-tooltip';
import EmployeeDetailsModal from './EmployeeDetailsModal'; // Import EmployeeDetailsModal

const EmployeeGrid = () => {
    const { CompanyID } = useContext(StatusContext);
    const { employees } = useContext(EmployeeContext);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

    const columns = useMemo(() => [
        { Header: 'Last Name', accessor: 'Last_Name' },
        { Header: 'First Name', accessor: 'First_Name' },
        { Header: 'Annual Salary', accessor: 'Annual_Salary' },
        {
            Header: 'Last Salary Update',
            accessor: 'Last_Salary_Adjustment_Date',
            Cell: ({ value }) => {
                const date = new Date(value);
                const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                return <span>{formattedDate}</span>;
            }
        },
        {
            Header: 'Hire Date',
            accessor: 'Date_of_Hire',
            Cell: ({ value }) => {
                const date = new Date(value);
                const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
                return <span>{formattedDate}</span>;
            }
        }
        // Add other columns as needed
    ], []);

    const getRowColor = (score) => {
        if (score >= 0.81) return 'bg-red-200';
        if (score >= 0.50 && score < 0.81) return 'bg-yellow-200';
        return 'bg-green-200';
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable({
        columns,
        data: employees,
        initialState: { pageIndex: 0, pageSize: 10 },
    }, useGlobalFilter, usePagination);

   // const selectedEmployee = employees.find(emp => emp.Employee_ID === selectedEmployeeId);

   const handleRowClick = (employeeId) => {
    setSelectedEmployeeId(employeeId);
    setShowDetailsModal(true);
};


    const closeModal = () => {
        setShowDetailsModal(false);
        setSelectedEmployeeId(null);
    };

return (
        <>
            <input
                value={globalFilter || ""}
                onChange={e => setGlobalFilter(e.target.value)}
                placeholder={"Search employees"}
                className="mb-4 p-2 border border-gray-300 rounded"
            />
            <div className="overflow-x-auto">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr 
                                    {...row.getRowProps()} 
                                    className={`${getRowColor(row.original.Flight_Risk_Score)} hover:bg-gray-100 cursor-pointer`}
                                    onClick={() => handleRowClick(row.original)}
                                    data-tip={`ID: ${row.original.Employee_ID}, Name: ${row.original.First_Name} ${row.original.Last_Name}`}
                                >
                                    {row.cells.map(cell => (
                                        <td {...cell.getCellProps()} className="px-6 py-4 whitespace-nowrap">
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>Next</button>
                <span>
                    Page {pageIndex + 1} of {pageOptions.length}
                </span>
                <select
                    value={pageSize}
                    onChange={e => setPageSize(Number(e.target.value))}
                >
                    {[10, 20, 30, 40, 50].map(size => (
                        <option key={size} value={size}>Show {size}</option>
                    ))}
                </select>
            </div>
            {showDetailsModal && selectedEmployeeId && (
                <EmployeeDetailsModal 

                CompanyId={CompanyID}
                employeeId={selectedEmployeeId.Employee_ID}
                onClose={closeModal}                
                />
            )}
            <Tooltip place="top" type="dark" effect="float" />
        </>
    );
};

export default EmployeeGrid;

