import React, { useState, useContext, useEffect, createContext } from "react";
import { AccountContext } from "./Account";
import {
  getApprovalGroupsEmail,
  getClientComps,
  getClients,
  getUsers,
  getUsersEntitlements,
} from "../global/api";

const StatusContext = createContext();

const Status = (props) => {
  const [AuthStatus, setAuthStatus] = useState(undefined);
  const [Email, setEmail] = useState(null);
  const [ClientID, setClientID] = useState(null);
  const [ClientName, setClientName] = useState(null);
  const [CompanyID, setCompanyID] = useState(null);
  const [CompanyName, setCompanyName] = useState(null);
  const [Admin, setAdmin] = useState(false);
  const [SuperAdmin, setSuperAdmin] = useState(false);
  const [Manager, setManager] = useState(false);
  const [Approver, setApprover] = useState(false);
  const [Auditor, setAuditor] = useState(false);
  const [HR, setHR] = useState(false);
  const [Executive, setExecutive] = useState(false);
  const [Recorder, setRecorder] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [isThereUser, setIsThereUser] = useState(undefined);
  const [ApprovalGroup, setApprovalGroup] = useState("");
  const [MultipleApprovalGroups, setMultipleApprovalGroups] = useState(false);

  const { getSession } = useContext(AccountContext);

  const updateClientAndCompany = async (newClientID, newCompanyID) => {
    setClientID(newClientID);
    setCompanyID(newCompanyID);

    try {
      // Fetch and update the client name
      if (newClientID) {
        const clientResults = await getClients();
        const client = clientResults.find(client => client.Client_ID === newClientID);
        setClientName(client?.Client_Name || 'Unknown Client');
      }

      // Fetch and update the company name
      if (newClientID && newCompanyID) {
        const companyResults = await getClientComps(newClientID);
        const company = companyResults.find(comp => comp.Company_ID === newCompanyID);
        setCompanyName(company?.Company_Name || 'Unknown Company');
      }
    } catch (error) {
      console.error("Error updating client and company information:", error);
    }
  };

  const refreshUserStatus = async () => {
    try {
      const session = await getSession();
      const sessionEmail = session.idToken.payload.email;
      setEmail(sessionEmail);

      const userResult = await getUsers(`Email_Address=${sessionEmail}`);
      if (userResult.length === 0) {
        setIsThereUser(false);
        setLoading(false);
        setAuthStatus(true);
      } else {
        setSuperAdmin(userResult[0]["Super_Admin"]);

        const userEntitlement = await getUsersEntitlements(sessionEmail);
        if (userEntitlement.length === 0) {
          setIsThereUser(false);
          setLoading(false);
          setAuthStatus(true);
        } else {
          setAdmin(userEntitlement[0]["Admin"]);
          setManager(userEntitlement[0]["Manager"]);
          setApprover(userEntitlement[0]["Approver"]);
          setAuditor(userEntitlement[0]["Auditor"]);
          setExecutive(userEntitlement[0]["Executive"]);
          setRecorder(userEntitlement[0]["Recorder"]);
          setHR(userEntitlement[0]["HR"]);
          setClientID(userEntitlement[0]["Client_ID"]);
          setCompanyID(userEntitlement[0]["Company_ID"]);
          const clientNameResult = await getClients();
          const client = clientNameResult.find(client => client.Client_ID === userEntitlement[0]["Client_ID"]);
          setClientName(client?.Client_Name || 'Unknown Client');
          const companyNameResult = await getClientComps(userEntitlement[0]["Client_ID"]);
          const company = companyNameResult.find(comp => comp.Company_ID === userEntitlement[0]["Company_ID"]);
          setCompanyName(company?.Company_Name || 'Unknown Company');
          const fetchedEmails = await getApprovalGroupsEmail(userEntitlement[0]["Company_ID"]);
          const filteredEmails = fetchedEmails.filter(email => email.Email_Address === userEntitlement[0]["Email_Address"]);
          setApprovalGroup(filteredEmails.length > 0 ? filteredEmails[0].Approval_Group_ID : "none");
          setMultipleApprovalGroups(filteredEmails.length > 1);
          setAuthStatus(true);
          setLoading(false);
          setIsThereUser(true);
        }
      }
    } catch (error) {
      console.error(error);
      setAuthStatus(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ClientID === null || CompanyID === null) {
      getSession().then(session => {
        setEmail(session.idToken.payload.email);
        refreshUserStatus();
      }).catch(error => {
        console.error(error);
        setAuthStatus(false);
        setLoading(false);
      });
    }
  }, [ClientID, CompanyID]);

  return (
    <StatusContext.Provider value={{
      AuthStatus, 
      Email, 
      ClientID, 
      ClientName, 
      CompanyID, 
      CompanyName, 
      Admin, 
      SuperAdmin, 
      Manager, 
      Approver, 
      Auditor, 
      HR, 
      Executive, 
      Recorder, 
      Loading, 
      isThereUser, 
      ApprovalGroup, 
      MultipleApprovalGroups,
      refreshUserStatus,
      updateClientAndCompany
    }}>
      {props.children}
    </StatusContext.Provider>
  );
};

export { Status, StatusContext };
