import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/common/sidebar_new";
import Footer from "../../global/Footer";
import TopNavigation from "../../components/common/TopNavigation";
import { CompanyDataProvider } from '../../contexts/CompanyContext';
import { CompanyChartDataProvider } from "../../contexts/CompanyChartDataContext";
import { EmployeeProvider } from "../../contexts/EmployeeContext"; // Import EmployeeProvider
import EmployeeGrid from "../../components/dashboard/EmployeeGrid"; // Import EmployeeGrid
import { StatusContext } from "../../contexts/Status";
import { RenderCompanyCards } from "../../components/dashboard/RenderCompanyCards";
import { RenderCompanyCharts } from '../../components/dashboard/RenderCompanyCharts';
import { RenderCompanySpark } from "../../components/dashboard/RenderCompanySpark";
import PendingApprovalsGrid from "../../components/approver/pendingApprovalGrid";
import { getCompanies } from "../../global/api"; // Import other necessary API functions
import ActionStatsGrid from "../../components/dashboard/actionStatsGrid";

const DashboardAdmin = () => {
    const { Email, clientID, clientName, CompanyID, companyName, approvalGroup } = useContext(StatusContext);
    const [companyData, setCompanyData] = useState(null);
    const [loadingCompanyData, setLoadingCompanyData] = useState(true);

    useEffect(() => {
        const fetchComps = async () => {
            setLoadingCompanyData(true);
            try {
                const fetchedData = await getCompanies(CompanyID);
                if (fetchedData && fetchedData.length > 0) {
                    setCompanyData(fetchedData);
                    console.log(fetchedData);
                }
            } catch (error) {
                console.error("Error fetching company data:", error);
            } finally {
                setLoadingCompanyData(false);
            }
        };
        fetchComps();
    }, [CompanyID]);

    // Include other API calls and useEffects if necessary

    return (
        <EmployeeProvider companyId={CompanyID}>
            <CompanyDataProvider companyId={CompanyID}>
                <div className="flex h-screen">
                    {/* Sidebar */}
                    <div className="fixed w-16"> {/* Adjust the width for the collapsed sidebar */}
                        <Sidebar />
                    </div>
    
                    {/* Main Content */}
                    <div className="flex flex-col bg-[#F2F2F2] items-center min-h-screen font-fabriga ml-16"> {/* Set margin-left to match the collapsed sidebar width */}
                        <TopNavigation />
                        <div className="flex flex-wrap justify-between items-start -mx-2">
                            <RenderCompanyCards companyData={companyData} />
                        </div>
                        <CompanyChartDataProvider>
                            <>
                                <div className="flex flex-wrap justify-between items-start -mx-2">
                                    <RenderCompanyCharts companyData={companyData} />
                                </div>
                                <div className="flex flex-wrap justify-between items-start -mx-2"></div>
                                <RenderCompanySpark companyData={companyData} />
                            </>
                        </CompanyChartDataProvider>
                        <div className="items-center w-full px-4">
                            <EmployeeGrid />
                            <ActionStatsGrid />
                            <PendingApprovalsGrid />
                        </div>
                        <Footer />
                    </div>
                </div>
            </CompanyDataProvider>
        </EmployeeProvider>
    );
    
};
export default DashboardAdmin;