import React from 'react';
import DataCard from './DataCard';
import SkeletonCard from '../common/SkeletonCard';

const RenderCompanyCards = ({ companyData }) => {
  // Check if companyData is still loading or empty
  if (!companyData || companyData.length === 0) {
    return <SkeletonCard />;
  }

  const calculateChangePercentage = (current, previous) => {
    if (!previous || !current) return "N/A";
    const change = (current - previous) / previous * 100;
    return change !== NaN ? `${change.toFixed(2)}%` : "0%";
  };

  // Assuming companyData is an array with the latest data at the end
  const latestData = companyData[companyData.length - 1];
  const previousData = companyData[companyData.length - 2] || {};

  // Extract and parse data for current and previous periods
  // Example for Total Risk (modify as per your actual data structure)
  const totalRisk = parseFloat(latestData.total_risk.replace(/[^\d.-]/g, ''));
  const totalRiskPrevious = parseFloat(previousData.total_risk?.replace(/[^\d.-]/g, '') || 0);
  const totalRiskChange = calculateChangePercentage(totalRisk, totalRiskPrevious);

  // Total Critical Risk (modify as per your actual data structure)
  const totalCriticalRisk = parseFloat(latestData.total_risk_critical.replace(/[^\d.-]/g, ''));
  const totalCriticalRiskPrevious = parseFloat(previousData.total_risk_critical?.replace(/[^\d.-]/g, '') || 0);
  const totalCriticalRiskChange = calculateChangePercentage(totalCriticalRisk, totalCriticalRiskPrevious);
  // Headcount calculation of change in Headcount this cycle
    const totalHeadcount = parseFloat(latestData.count_total);
    const totalHeadcountPrevious = parseFloat(previousData.count_total || 0);
    const totalHeadcountChange = calculateChangePercentage(totalHeadcount, totalHeadcountPrevious);

  const formattedDate = new Date(latestData.Period_Date).toLocaleDateString();

  return (
    <div className="flex flex-wrap justify-between items-start -mx-2">
            <DataCard className="w-full flex flex-wrap md:w-1/2 lg:w-1/4 space-between -mx-2"
            key="total-risk"
            type="Total Risk"
            amount={`$${totalRisk.toFixed(2)}`}
            change={totalRiskChange}
            lastUpdate={`Last updated: ${formattedDate}`}
            /> 
            <>
            <DataCard
                key="total-critical-risk"
                type="Total Critical Risk"
                amount={`$${totalCriticalRisk.toFixed(2)}`}
                change={totalCriticalRiskChange}
                lastUpdate={`Last updated: ${formattedDate}`}
            />
            <DataCard
                key="Headcount"
                type="Headcount Change"
                amount={totalHeadcount}
                change={totalHeadcountChange}
                lastUpdate={`Last updated: ${formattedDate}`}
            />
            ;{/* Add other cards as needed */}
        </>
    </div>  
  );
};

export {RenderCompanyCards};
