import React, {useContext} from "react";
import DashboardManager from "../../pages/manager/Dashboard";
import {StatusContext} from "../../contexts/Status";
import {Outlet} from "react-router-dom";
import NotFound from "../../global/NotFound.js";
import Spinner from "../../global/Spinner";

const IsSuper = () => {
  const {SuperAdmin, Loading} = useContext(StatusContext);

  if (Loading === true) {
    if (SuperAdmin === null) {
      return <Spinner />;
    }
  }

  return SuperAdmin ? <Outlet /> : <NotFound />;
};

export default IsSuper;
