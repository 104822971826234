import React, { createContext, useState, useEffect } from 'react';
import { getEmployees } from '../global/api';

export const EmployeeContext = createContext();

export const EmployeeProvider = ({ children, companyId }) => {
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const fetchedEmployees = await getEmployees(companyId);
                console.log("Fetched Employees:", fetchedEmployees); // Log the fetched data
                setEmployees(fetchedEmployees);
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
        };

        if (companyId) {
            fetchEmployees();
        }
    }, [companyId]);

    return (
        <EmployeeContext.Provider value={{ employees }}>
            {children}
        </EmployeeContext.Provider>
    );
};
