import axios from "axios";
import Pool from "../auth/UserPool";

const API_URL = process.env.REACT_APP_API_URL;

const APROVEGROUP_API_URL = API_URL + "prod/tim/approvalgroup";
const AGEMAIL_API_URL = API_URL + "prod/tim/approvalgroup/email";
const ATTRIT_CO_API_URL = API_URL + "prod/tim/attrit/co";
const ATTRIT_LOC_API_URL = API_URL + "prod/tim/attrit/loc";
const ATTRIT_DEPT_API_URL = API_URL + "prod/tim/attrit/dept";
const ATTRIT_JOB_API_URL = API_URL + "prod/tim/attrit/job";
const CLIENT_API_URL = API_URL + "prod/tim/clients";
const CLIENTCOMP_API_URL = API_URL + "prod/tim/clients/companies";
const COMPANY_API_URL = API_URL + "prod/tim/company";
const ACTION_API_URL = API_URL + "prod/tim/company/action";
const DEPARTMENT_API_URL = API_URL + "prod/tim/company/department";
const DEPTDETAIL_API_URL = API_URL + "prod/tim/company/deptdetail";
const JOB_API_URL = API_URL + "prod/tim/company/job";
const JOBDETAIL_API_URL = API_URL + "prod/tim/company/jobdetail";
const LOCATION_API_URL = API_URL + "prod/tim/company/location";
const LOCDETAIL_API_URL = API_URL + "prod/tim/company/locationdetail";
const ORG_API_URL = API_URL + "prod/tim/company/org";
const ACTIONS_API_URL = API_URL + "prod/tim/config/actions";
const WORKFLOWS_API_URL = API_URL + "prod/tim/config/approvals";
const EMAIL_API_URL = API_URL + "prod/tim/email/";
const EMAILTEMPLATE_API_URL = API_URL + "prod/tim/email/template";
const EMP_API_URL = API_URL + "prod/tim/emp";
const LOAD_API_URL = API_URL + "prod/tim/load";
const LOAD_DEPT_API_URL = API_URL + "prod/tim/load/dept";
const LOAD_LOC_API_URL = API_URL + "prod/tim/load/loc";
const LOAD_JOB_API_URL = API_URL + "prod/tim/load/job";
const LOAD_ORG_API_URL = API_URL + "prod/tim/load/org";
const LOAD_DELETE_API_URL = API_URL + "prod/tim/load/delete";
const LOAD_EMP_API_URL = API_URL + "prod/tim/load/emp";
const TENURE_API_URL = API_URL + "prod/tim/ten";
const USER_API_URL = API_URL + "prod/tim/user";
const USER_ENTITLEMENTS_API_URL = API_URL + "prod/tim/user/entitle";
const WAGEANALYSIS_API_URL = API_URL + "prod/tim/wa";

const getToken = async () => {
  return new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser(); // Ensure Pool is imported or available in this scope
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          return reject("Error during session retrieval: " + err);
        }
        resolve(session);
      });
    } else {
      return reject("No current user found");
    }
  });
};

async function makeAuthorizedApiCall(
  url,
  httpMethod,
  data,
  additionalHeaders = {}
) {
  try {
    const session = await getToken();

    if (!session || !session.idToken || !session.idToken.jwtToken) {
      throw new Error("Session or ID Token is undefined");
    }

    const idToken = session.idToken.jwtToken;

    const headers = {
      ...additionalHeaders,
      Authorization: `Bearer ${idToken}`,
    };

    const config = {
      method: httpMethod,
      url,
      headers,
      data,
    };

    const response = await axios(config);
    return response.data;
  } catch (err) {
    console.error("Error in makeAuthorizedApiCall:", err);
    throw err;
  }
}

// Approval Group related API calls
export async function getApprovalGroups(company_id) {
  let apiUrl = APROVEGROUP_API_URL;
  if (company_id) {
    apiUrl += `?Company_ID=${company_id}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createApprovalGroup(
  company_id,
  approval_group_ID,
  approval_group_name
) {
  const apiUrl = APROVEGROUP_API_URL;
  return await makeAuthorizedApiCall(
    apiUrl,
    "post",
    company_id,
    approval_group_ID,
    approval_group_name
  );
}

export async function updateApprovalGroup(
  company_id,
  approval_group_ID,
  approval_group_name
) {
  const apiUrl = `${APROVEGROUP_API_URL}/${company_id}`;
  return await makeAuthorizedApiCall(
    apiUrl,
    "put",
    company_id,
    approval_group_ID,
    approval_group_name
  );
}

export async function deleteApprovalGroup(company_id) {
  const apiUrl = `${APROVEGROUP_API_URL}?Company_ID=${company_id}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Approval Group Email related API calls
export async function getApprovalGroupsEmail(
  company_id,
  approval_group_ID,
  Email_Address
) {
  let apiUrl = AGEMAIL_API_URL;

  // Create an array to store query parameters
  const queryParams = [];

  if (company_id) {
    queryParams.push(`Company_ID=${company_id}`);
  }

  if (approval_group_ID) {
    queryParams.push(`Approval_Group_ID=${approval_group_ID}`);
  }

  if (Email_Address) {
    queryParams.push(`Email_Address=${Email_Address}`);
  }

  // Combine the query parameters with "&" and add them to the URL
  if (queryParams.length > 0) {
    apiUrl += `?${queryParams.join("&")}`;
  }

  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createApprovalGroupEmail(approvalGroupEmail) {
  const apiUrl = AGEMAIL_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", approvalGroupEmail);
}

export async function deleteApprovalGroupEmail(
  company_id,
  approval_group_ID,
  Email_Address
) {
  const apiUrl = `${AGEMAIL_API_URL}?Company_ID=${company_id}&Approval_Group_ID=${approval_group_ID}&Email_Address=${Email_Address}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Attrition Analysis related API calls

export async function getAttritionAnalComp(Company_ID) {
  const apiUrl = `${ATTRIT_CO_API_URL}?Company_ID=${Company_ID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getAttritionAnalLoc(Company_ID, Location_ID) {
  let apiUrl = ATTRIT_LOC_API_URL;
  if (Location_ID) {
    apiUrl += `?Company_ID=${Company_ID}&Work_Location_ID=${Location_ID}`;
  } else {
    apiUrl += `?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getAttritionAnalDept(
  Company_ID,
  Department_ID,
  Location_ID
) {
  let apiUrl = ATTRIT_DEPT_API_URL;
  if (Location_ID) {
    apiUrl += `?Company_ID=${Company_ID}&Department_ID=${Department_ID}`;
  } else {
    apiUrl += `?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getAttritionAnalJob(Company_ID, Job_ID, Location_ID) {
  let apiUrl = ATTRIT_JOB_API_URL;
  if (Location_ID) {
    apiUrl += `?Company_ID=${Company_ID}&Job_ID=${Job_ID}`;
  } else {
    apiUrl += `?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

// Client related API calls
export async function getClients(clientID) {
  let apiUrl = CLIENT_API_URL;
  if (clientID) {
    apiUrl += `?Client_ID=${clientID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createClient(client) {
  const apiUrl = CLIENT_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", client);
}

export async function updateClient(clientId, updatedClient) {
  const apiUrl = `${CLIENT_API_URL}/?Client_ID=${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedClient);
}

export async function deleteClient(clientId) {
  const apiUrl = `${CLIENT_API_URL}?Client_ID=${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Client Company related API calls
export async function getClientComps(clientid) {
  const apiUrl = `${CLIENTCOMP_API_URL}?Client_ID=${clientid}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createClientComp(clientCompany) {
  return await makeAuthorizedApiCall(CLIENTCOMP_API_URL, "post", clientCompany);
}
export async function updateClientComp(clientCompany) {
  return await makeAuthorizedApiCall(CLIENTCOMP_API_URL, "put", clientCompany);
}

export async function deleteClientComp(clientid, company_id) {
  const apiUrl = `${CLIENTCOMP_API_URL}?Client_ID=${clientid}&Company_ID=${company_id}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Company related API calls

export async function getCompanies(company_id) {
  const apiUrl = `${COMPANY_API_URL}?Company_ID=${company_id}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createCompany(company) {
  const apiUrl = COMPANY_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", company);
}

export async function updateCompany(id, company) {
  const apiUrl = `${COMPANY_API_URL}/${id}`;
  return await makeAuthorizedApiCall(apiUrl, "put", company);
}

export async function deleteCompany(id) {
  const apiUrl = `${COMPANY_API_URL}/${id}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Config Action related API calls
export async function getActions(companyId, actionId) {
  let apiUrl = `${ACTIONS_API_URL}?Company_ID=${companyId}`;
  if (actionId) {
    apiUrl += `&Action_ID=${actionId}`;
  }

  return await makeAuthorizedApiCall(apiUrl, "get");
}
export async function createAction(companyId, action) {
  const apiUrl = `${ACTIONS_API_URL}?Company_ID=${companyId}`;
  return await makeAuthorizedApiCall(apiUrl, "post", action);
}

export async function updateAction(companyId, actionId, updatedAction) {
  const apiUrl = `${ACTIONS_API_URL}?Company_ID=${companyId}&Action_ID=${actionId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedAction);
}

export async function deleteAction(companyId, actionId) {
  const apiUrl = `${ACTIONS_API_URL}?Company_ID=${companyId}&Action_ID=${actionId}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Company Action related API calls
export async function callActionsAPI(actionType, payload) {
  const apiUrl = ACTION_API_URL;
  const data = {
    action_type: actionType,
    ...payload,
  };
  return await makeAuthorizedApiCall(apiUrl, "post", data);
}

export async function actionInit(action) {
  const apiUrl = `${ACTION_API_URL}initiate`;
  return await makeAuthorizedApiCall(apiUrl, "post", action);
}

export async function actionAdvance(action) {
  const apiUrl = `${ACTION_API_URL}update-and-advance`;
  return await makeAuthorizedApiCall(apiUrl, "post", action);
}
export async function actionReject(actionType, payload) {
  const apiUrl = `${ACTION_API_URL}reject`;
  const data = {
    action_type: actionType,
    ...payload,
  };
  return await makeAuthorizedApiCall(apiUrl, "post", data);
}

export async function actionStat(Company_ID) {
  const apiUrl = `${ACTION_API_URL}/approval-stats?company_id=${Company_ID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}
export async function actionCompleted(CompanyID, EmployeeID) {
  const apiUrl = `${ACTION_API_URL}/completed-actions?Company_ID=${CompanyID}&Employee_ID=${EmployeeID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function actionPending(companyID, approvalGroup) {
  const apiUrl = `${ACTION_API_URL}/pending-actions?Company_ID=${companyID}&Approval_Group_ID=${approvalGroup}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

// Department API Call
export async function getDepartment(companyId) {
  const apiUrl = `${DEPARTMENT_API_URL}?Company_ID=${companyId}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}
// Department Detail related API calls

export async function getDeptDetails(CompanyId) {
  const apiUrl = `${DEPTDETAIL_API_URL}?Company_ID=${CompanyId}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createDeptDetail(client) {
  const apiUrl = DEPTDETAIL_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", client);
}

export async function updateDeptDetail(clientId, updatedClient) {
  const apiUrl = `${DEPTDETAIL_API_URL}${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedClient);
}

export async function deleteDeptDetail(clientId) {
  const apiUrl = `${DEPTDETAIL_API_URL}${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Employee related API calls
export async function getEmployees(Company_ID, Department_ID, Location_ID) {
  let apiUrl = EMP_API_URL;
  if (Department_ID) {
    apiUrl += `?Company_ID=${Company_ID}&Department_ID=${Department_ID}`;
  } else if (Location_ID) {
    apiUrl += `?Company_ID=${Company_ID}&Work_Location_ID=${Location_ID}`;
  } else {
    apiUrl += `?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

// Employee related API calls
export async function getEmployee(Company_ID, Employee_ID) {
  let apiUrl = EMP_API_URL;
    apiUrl += `?Company_ID=${Company_ID}&Employee_ID=${Employee_ID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

// there should be a differnt endpoint for this one since create employee can be used to create a single record
export async function createEmployee(employee) {
  const apiUrl = `${API_URL}prod/tim/load/csv`;
  const headers = {
    "Content-Type": "text/csv",
  };
  return await makeAuthorizedApiCall(apiUrl, "POST", employee, headers);
}

export async function updateEmployee(companyId, employeeId, updatedEmployee) {
  const apiUrl = `${EMP_API_URL}/employee/${companyId}/${employeeId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedEmployee);
}

export async function deleteEmployee(companyId, employeeId) {
  const apiUrl = `${EMP_API_URL}/employee/${companyId}/${employeeId}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Job related API calls
export async function getJobs(companyId) {
  const apiUrl = `${JOB_API_URL}?Company_ID=${companyId}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

// Job detail related API calls

export async function getJobDetails(CompanyId) {
  const apiUrl = `${JOBDETAIL_API_URL}?companyId=${CompanyId}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createJobDetail(client) {
  const apiUrl = JOBDETAIL_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", client);
}

export async function updateJobDetail(clientId, updatedClient) {
  const apiUrl = `${JOBDETAIL_API_URL}${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedClient);
}

export async function deleteJobDetail(clientId) {
  const apiUrl = `${JOBDETAIL_API_URL}${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}
// Critical Job  related API calls

export async function getCriticalJobs(CompanyId) {
  const apiUrl = `${JOB_API_URL}/critical?Company_ID=${CompanyId}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createCriticalJob(client) {
  const apiUrl = `${JOB_API_URL}/critical`;
  return await makeAuthorizedApiCall(apiUrl, "post", client);
}

export async function updateCriticalJob(clientId, updatedClient) {
  const apiUrl = `${JOB_API_URL}/critical${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedClient);
}

export async function deleteCriticalJob(clientId) {
  const apiUrl = `${JOB_API_URL}/critical${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// location related calls
export async function getLocations(companyId) {
  const apiUrl = `${LOCATION_API_URL}?Company_ID=${companyId}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

// Location detail related API calls
export async function getLocDetails(CompanyId) {
  const apiUrl = `${LOCDETAIL_API_URL}?Company_ID=${CompanyId}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createLocDetail(client) {
  const apiUrl = LOCDETAIL_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", client);
}

export async function updateLocDetail(clientId, updatedClient) {
  const apiUrl = `${LOCDETAIL_API_URL}${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedClient);
}

export async function deleteLocDetail(clientId) {
  const apiUrl = `${LOCDETAIL_API_URL}${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Workflows related API calls
// these use the /config/approvals since workflows are approval flows.
export async function getWorkflows(companyId, actionId) {
  let apiUrl = `${WORKFLOWS_API_URL}?Company_ID=${companyId}`;
  if (actionId) {
    apiUrl += `&Action_Group_ID=${actionId}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createWorkflow(companyId, workflow) {
  const apiUrl = `${WORKFLOWS_API_URL}?Company_ID=${companyId}`;
  return await makeAuthorizedApiCall(apiUrl, "post", workflow);
}

export async function updateWorkflow(companyId, actionId, updatedWorkflow) {
  const apiUrl = `${WORKFLOWS_API_URL}?Company_ID=${companyId}&Action_Group_ID=${actionId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedWorkflow);
}

export async function deleteWorkflow(companyId, actionId) {
  const apiUrl = `${WORKFLOWS_API_URL}?Company_ID=${companyId}&Action_Group_ID=${actionId}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}
// User related API calls
export async function getUsers(params) {
  const apiUrl = `${USER_API_URL}?${params}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createUser(user) {
  return await makeAuthorizedApiCall(USER_API_URL, "post", user);
}

export async function updateUser(id, user) {
  const apiUrl = `${USER_API_URL}/${id}`;
  return await makeAuthorizedApiCall(apiUrl, "put", user);
}

export async function deleteUser(id) {
  const apiUrl = `${USER_API_URL}/${id}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// User Entitlements related API calls
export async function getUserEntitlements(Email_Address) {
  let apiUrl = USER_ENTITLEMENTS_API_URL;
  if (Email_Address) {
    apiUrl += `?Email_Address=${Email_Address}`;
  }

  return await makeAuthorizedApiCall(apiUrl, "get");
}
export async function getUsersEntitlements(Email_Address, Client_ID) {
  let apiUrl = USER_ENTITLEMENTS_API_URL;
  if (Email_Address) {
    apiUrl += `?Email_Address=${Email_Address}`;
  } else if (Client_ID) {
    apiUrl += `?Client_ID=${Client_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getUsersEntitleByClient(Client_ID) {
  const apiUrl = `${USER_ENTITLEMENTS_API_URL}?Client_ID=${Client_ID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createUserEntitlement(user) {
  const apiUrl = USER_ENTITLEMENTS_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", user);
}

export async function updateUserEntitlement(updatedUser) {
  const apiUrl = USER_ENTITLEMENTS_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedUser);
}

export async function deleteUserEntitlement(email_address) {
  const apiUrl = `${USER_ENTITLEMENTS_API_URL}?Email_Address=${email_address}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

// Organization related API calls

export async function getOrgs(companyId, Email) {
  let apiUrl = `${ORG_API_URL}?Company_ID=${companyId}`;
  if (Email) {
    apiUrl += `&Email_Address=${Email}`;
  }

  return await makeAuthorizedApiCall(apiUrl, "get");
}

// Email Template related API calls
export async function getEmailTemplates() {
  return await makeAuthorizedApiCall(EMAILTEMPLATE_API_URL, "get");
}

export async function createEmailTemplate(emailTemplate) {
  return await makeAuthorizedApiCall(
    EMAILTEMPLATE_API_URL,
    "post",
    emailTemplate
  );
}

export async function deleteEmailTemplate(id) {
  const apiUrl = `${EMAILTEMPLATE_API_URL}/${id}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

export async function addEmailTemplate(template) {
  const apiUrl = EMAIL_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", template);
}

export async function updateEmailTemplate(Template_ID, updatedTemplate) {
  const apiUrl = `${EMAIL_API_URL}?Template_ID=${Template_ID}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedTemplate);
}

//email occurence functions

export async function getEmails(TemplateId) {
  let apiUrl = EMAIL_API_URL;
  if (TemplateId) {
    apiUrl += `?Template_ID=${TemplateId}`;
  }

  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function createEmail(template) {
  const apiUrl = EMAIL_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", template);
}

export async function updateEmail(clientId, updatedClient) {
  const apiUrl = `${EMAIL_API_URL}${clientId}`;
  return await makeAuthorizedApiCall(apiUrl, "put", updatedClient);
}

export async function deleteEmail(Template_ID) {
  const apiUrl = `${EMAIL_API_URL}?Template_ID=${Template_ID}`;
  return await makeAuthorizedApiCall(apiUrl, "delete");
}

const SENDEMAIL_API_URL = API_URL + "prod/tim/email/send/";

export async function sendEmail() {
  const apiUrl = SENDEMAIL_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post");
}

const INIT_ACTION_API_URL = API_URL + "prod/tim/company/action/initiate/";

export async function initiateAction(action) {
  const apiUrl = INIT_ACTION_API_URL;
  return await makeAuthorizedApiCall(apiUrl, "post", action);
}

const COMPLETED_ACTION_API_URL =
  API_URL + "prod/tim/company/action/completed-actions/";

export async function getCompletedActions(CompanyID, EmployeeID) {
  const apiUrl = `${COMPLETED_ACTION_API_URL}?Company_ID=${CompanyID}&Employee_ID=${EmployeeID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function loadEmployee(employee) {
  const apiUrl = `${LOAD_API_URL}/csv`;
  const headers = {
    "Content-Type": "text/csv",
  };
  return await makeAuthorizedApiCall(apiUrl, "POST", employee, headers);
}

export async function loadProcess() {
  return await makeAuthorizedApiCall(`${LOAD_API_URL}/process`, "post");
}

// Tenure Analysis related API calls

export async function getTenureAnalComp(Company_ID) {
  const apiUrl = `${TENURE_API_URL}/co?company_ids=${Company_ID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}
export async function getTenureAnalLoc(Company_ID, Location_ID) {
  let apiUrl = TENURE_API_URL;
  if (Location_ID) {
    apiUrl += `loc?Company_ID=${Company_ID}&Work_Location_ID=${Location_ID}`;
  } else {
    apiUrl += `loc?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getTenureAnalDept(Company_ID, Department_ID) {
  let apiUrl = TENURE_API_URL;
  if (Department_ID) {
    apiUrl += `dept?Company_ID=${Company_ID}&Department_ID=${Department_ID}`;
  } else {
    apiUrl += `dept?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getTenureAnalJob(Company_ID, Job_ID) {
  let apiUrl = TENURE_API_URL;
  if (Job_ID) {
    apiUrl += `job?Company_ID=${Company_ID}&Job_ID=${Job_ID}`;
  } else {
    apiUrl += `job?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

// WageAnalysis related API calls

export async function getWageAnalComp(Company_ID) {
  const apiUrl = `${WAGEANALYSIS_API_URL}/co?company_ids=${Company_ID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getWageAnalLoc(Company_ID, Location_ID) {
  let apiUrl = WAGEANALYSIS_API_URL;
  if (Location_ID) {
    apiUrl += `loc?Company_ID=${Company_ID}&Work_Location_ID=${Location_ID}`;
  } else {
    apiUrl += `loc?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getWageAnalDept(Company_ID, Department_ID) {
  let apiUrl = WAGEANALYSIS_API_URL;
  if (Department_ID) {
    apiUrl += `dept?Company_ID=${Company_ID}&Department_ID=${Department_ID}`;
  } else {
    apiUrl += `dept?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

export async function getWageAnalJob(Company_ID, Job_ID) {
  let apiUrl = WAGEANALYSIS_API_URL;
  if (Job_ID) {
    apiUrl += `job?Company_ID=${Company_ID}&Job_ID=${Job_ID}`;
  } else {
    apiUrl += `job?Company_ID=${Company_ID}`;
  }
  return await makeAuthorizedApiCall(apiUrl, "get");
}

const LOADID_API_URL = API_URL + "prod/tim/load/csv";

export async function getLoadID(loadID) {
  const apiUrl = `${LOADID_API_URL}?load_id=${loadID}`;
  return await makeAuthorizedApiCall(apiUrl, "get");
}

const DELLOADID_API_URL = API_URL + "prod/tim/load/delete/loadid";

export async function deleteLoadID(loadID) {
  const apiUrl = `${DELLOADID_API_URL}?load_id=${loadID}`;
  return await makeAuthorizedApiCall(apiUrl, "post");
}
