import React, { useState, useContext, useEffect } from 'react';
import { getEmployee, getActions, initiateAction } from '../../global/api';
import { StatusContext } from '../../contexts/Status';

const EmployeeDetailsModal = ({ CompanyId, employeeId, onClose }) => {
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [actions, setActions] = useState([]);
    const [selectedAction, setSelectedAction] = useState('');
    const [regrettable, setRegrettable] = useState(false);
    const [regretComment, setRegretComment] = useState('');
    const [actionComment, setActionComment] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const {Email} = useContext(StatusContext)

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const data = await getEmployee(CompanyId, employeeId);
                setEmployeeDetails(data);
            } catch (err) {
                setError('Failed to fetch employee details');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };




        const fetchActions = async () => {
            try {
                const data = await getActions(CompanyId);
                setActions(data);
            } catch (err) {
                console.error('Error fetching actions:', err);
            }
        };

        if (employeeId && CompanyId) {
            fetchEmployeeDetails();
            fetchActions();
        }
    }, [employeeId, CompanyId]);

    const initAction = async () => {
        if (!selectedAction || !employeeDetails) {
            alert("Please select an action and ensure employee details are loaded.");
            return;
        }

        const actionPayload = {
            Company_Id: CompanyId,
            Employee_Id: employeeDetails.Employee_ID, // Assuming employeeDetails is directly accessible
            Work_Location_Id: employeeDetails.Work_Location_ID,
            Department_Id: employeeDetails.Department_ID,
            Job_Id: employeeDetails.Job_ID,
            Action_Id: selectedAction.Action_ID, // Corrected to use Action_ID from selectedAction object
            Action_Init_Date: new Date().toISOString(),
            user_id: Email.email, // Corrected for Email object
            regret: regrettable,
            Action_Comment: actionComment,
           // Regret_Comment: regretComment,
        };

        try {
            await initiateAction(actionPayload);
            alert('Action successfully initiated!'); // Provide immediate feedback
            onClose(); // Close the modal
        } catch (error) {
            console.error("Error initiating action:", error);
            alert('Failed to initiate action.'); // Provide error feedback
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const handleSubmit = () => {
        // Call initAction when submit button is clicked
        initAction();
    };

    const handleActionChange = (e) => {
        const actionId = e.target.value;
        const action = actions.find(a => a.Action_ID === actionId);
        setSelectedAction(action);
    };

    if (loading) {
        return <div className="text-center p-4">Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500 text-center p-4">{error}</div>;
    }

    const canSubmit = selectedAction && actionComment.trim() !== '';
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-1/2 shadow-lg rounded-md bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        {employeeDetails[0]['First_Name']} {employeeDetails[0]['Last_Name']}

                    </h3>
                    <div>
                    <button 
                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2 ${!canSubmit && 'opacity-50 cursor-not-allowed'}`}
                    onClick={handleSubmit} // Here's the change
                    disabled={!canSubmit}
                        >
                            Submit
                        </button>
                        <button 
                            onClick={onClose} 
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-1/2 pr-2">
                        {/* Left column content */}
                        <p className="text-sm text-gray-500">Employee ID: {employeeDetails[0]['Employee_ID']}</p>
                        <p className="text-sm text-gray-500">Annual Salary: {employeeDetails[0]['Annual_Salary']}</p>
                        <p className="text-sm text-gray-500">Last Salary Update: {formatDate(employeeDetails[0]['last_Salary_Adjustment_Date'])}</p>
                        <p className="text-sm text-gray-500">Hire Date: {formatDate(employeeDetails[0]['Date_of_Hire'])}</p>
                    </div>
                    <div className="w-1/2 pl-2">
                        {/* Right column content */}
                        <div className="my-4">
                            <p className="text-md font-medium text-gray-700">Performance Chart Placeholder</p>
                            {/* Chart component insertion */}
                        </div>
                        <div className="my-4">
                            <label className="inline-flex items-center">
                                <input 
                                    type="checkbox" 
                                    className="form-checkbox" 
                                    checked={regrettable} 
                                    onChange={(e) => setRegrettable(e.target.checked)} 
                                />
                                <span className="ml-2 text-gray-700">Regrettable</span>
                            </label>
                            {regrettable && (
                                <textarea 
                                    className="form-textarea mt-1 block w-full" 
                                    rows="3" 
                                    placeholder="Add a note explaining the change"
                                    value={regretComment}
                                    onChange={(e) => setRegretComment(e.target.value)}
                                ></textarea>
                            )}
                        </div>
                        <div className="my-4">
                        <select
                            className="form-select block w-full mt-1"
                            value={selectedAction}
                            onChange={handleActionChange}
                        >
                            <option value="">Select an Action</option>
                            {actions.map((action) => (
                                <option key={action.Action_ID} value={action.Action_ID}>{action.Action_Name}</option>
                            ))}
                        </select>
                            {selectedAction && (
                                <textarea 
                                    className="form-textarea mt-1 block w-full" 
                                    rows="3" 
                                    placeholder="Add a note explaining your action"
                                    value={actionComment}
                                    onChange={(e) => setActionComment(e.target.value)}
                                ></textarea>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeDetailsModal;
